import { TextField as AriaTextField, type TextFieldProps as AriaTextFieldProps } from "react-aria-components";
import { tv, type VariantProps } from "tailwind-variants";
import { Description, FieldError, Input, Label, fieldBorderStyles } from "./Field";
import { composeTailwindRenderProps, inputBase } from "./utils";
import type { FieldMetadata } from "@conform-to/react";
import type { ReactNode } from "react";

export const InputStyles = tv({
  extend: inputBase,
  base: "outline outline-blue-500 outline-offset-0",
  variants: {
    variant: {
      small: "text-sm py-1 px-1.5",
    },
    isFocused: fieldBorderStyles.variants.isFocusWithin,
    ...fieldBorderStyles.variants,
  },
});

export interface TextFieldProps extends AriaTextFieldProps {
  label?: ReactNode;
  description?: string | null;
  placeholder?: string;
  conformTo?: FieldMetadata<string>;
  isLoading?: boolean;
  inputVariant?: VariantProps<typeof InputStyles>;
}

export function TextField({
  label,
  description,
  placeholder,
  conformTo,
  isLoading,
  inputVariant,
  ...props
}: TextFieldProps) {
  if (isLoading) {
    props.isReadOnly = true;
    props.isDisabled = true;
  }
  return (
    <AriaTextField
      {...props}
      name={props.name ?? conformTo?.name}
      defaultValue={props.defaultValue ?? conformTo?.initialValue}
      {...[conformTo?.required ? { isRequired: "true" } : null]}
      className={composeTailwindRenderProps(props.className, "flex flex-col gap-1")}
    >
      {label && (
        <div className="flex gap-2">
          <Label>{label}</Label>
        </div>
      )}
      <Input
        placeholder={placeholder}
        className={composeTailwindRenderProps(InputStyles, conformTo?.errors ? "ring-2 ring-red-500" : "")}
      />
      {description && <Description>{description}</Description>}
      <FieldError>{conformTo?.errors}</FieldError>
    </AriaTextField>
  );
}
